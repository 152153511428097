import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { render } from "react-dom";

import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";

import App from "./App.js";
import "./css/style.scss";
import "./fonts/Montserrat/Montserrat-Bold.ttf";

import "./redux/axios/interceptor.request";
import "./redux/axios/interceptor.response";

const store = configureStore();

render(
  <>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </>,
  document.getElementById("root")
);

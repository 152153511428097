import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as menuActions from "../../redux/actions/menuActions";
import * as cartActions from "../../redux/actions/cartActions";
import { bindActionCreators } from "redux";
// import MenuCategories from "./menu/MenuCategories";
import MenuList from "./menu/MenuList";
import { toast } from "react-toastify";
import UnAuthorizedRestaurant from "./shared/UnAuthorizedRestaurant";
import LoadingMenu from "./shared/LoadingMenu";

function RestaurantMenu({ cartActions, cart, actions, menu, loading_menu, error_menu }) {
  let { restaurant_id } = useParams();
  const [restaurantId, setRestaurantId] = useState();

  useEffect(() => {
    const id = restaurant_id.split("-")[0];
    setRestaurantId(parseInt(id));
    actions.fetchRestaurant(id);
  }, [restaurant_id, actions]);

  useEffect(() => {
    cartActions.successOrder(false);
    if (menu && menu.can_take_orders) {
      cartActions.calculateTotal(cart, menu);
    }
  }, [cart, menu, cartActions]);

  const addDishToCart = (data) => {
    cartActions.addDishToCart({ ...data, restaurantId: restaurantId });
    toast.success("Plat ajouté au panier", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const addFormulaToCart = (data) => {
    cartActions.addFormulaToCart({ ...data, restaurantId: restaurantId });
    toast.success("Menu ajouté au panier");
  };

  if ((menu && !menu.can_take_orders) || error_menu) {
    return (
      <UnAuthorizedRestaurant message="Ce restaurant n'utilise plus notre outil de commande en ligne" />
    );
  }
  if (loading_menu) {
    return <LoadingMenu />;
  }

  return (
    <Container>
      {/* {menu && (
        <MenuCategories
          menu_categories={menu.menu_categories}
          menu_formulas={menu.formulas}
        />
      )} */}
      {menu && menu.can_take_orders && (
        <MenuList
          menu={menu}
          addDishToCart={addDishToCart}
          addFormulaToCart={addFormulaToCart}
        />
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    menu: state.menuReducer.menu,
    loading_menu: state.menuReducer.loading_menu,
    error_menu: state.menuReducer.error_menu,
    cart: state.cartReducer.cart,
    total: state.cartReducer.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(menuActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantMenu);

import configGlobal from "../../config";
const axios = require("axios");

export const cartService = {
  sendVerificationSms,
  submitOrder,
  fetchOrdersHistory,
};

function sendVerificationSms(code, phone) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios.post(
    `${configGlobal.api_url}/send_verification_sms`,
    { verification_code: code, phone: phone },
    config
  );
}
function submitOrder(data) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return axios.post(`${configGlobal.api_url}/food_order`, { food_order: data }, config);
}

function fetchOrdersHistory(data) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return axios.post(
    `${configGlobal.api_url}/orders_history`,
    { history_data: data },
    config
  );
}

import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
function CardSection({ name, setName }) {
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  return (
    <div>
      <label>Card details</label>
      {/* add name later */}
      <div className="py-3">
        <input
          value={name}
          onChange={handleChangeName}
          className="card-name"
          placeholder="Nom ..."
        />
      </div>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
}
export default CardSection;

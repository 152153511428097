export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const ADD_DISH_TO_CART = "ADD_DISH_TO_CART";
export const ADD_FORMULA_TO_CART = "ADD_FORMULA_TO_CART";
export const REMOVE_FORMULA_FROM_CART = "REMOVE_FORMULA_FROM_CART";
export const REMOVE_DISH_FROM_CART = "REMOVE_DISH_FROM_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const LOAD_CART = "LOAD_CART";
export const RESET_CART = "RESET_CART";
export const SET_MENU = "SET_MENU";
export const SET_TOTAL = "SET_TOTAL";
export const SET_TAKABLE_INFO = "SET_TAKABLE_INFO";
export const SUCCESS_ORDER = "SUCCESS_ORDER";
export const VALIDATED_CART = "VALIDATED_CART";
export const VALIDATED_TAKEABLE = "VALIDATED_TAKEABLE";
export const RESET_TAKABLE_INFO = "RESET_TAKABLE_INFO";
export const SET_VALIDATED_TOTAL = "SET_VALIDATED_TOTAL";
export const SET_ORDERS_HISTORY = "SET_ORDERS_HISTORY";
export const LOADING_ORDERS_HISTORY = "LOADING_ORDERS_HISTORY";
export const LOADING_MENU = "LOADING_MENU";
export const SET_CLIENT_SECRET = "SET_CLIENT_SECRET";
export const SET_IS_PROCESSING = "SET_IS_PROCESSING";
export const STRIPE_REQUIRE_ACTION = "STRIPE_REQUIRE_ACTION";
export const SET_PAYMENT_INTENT_CLIENT_SECRET = "SET_PAYMENT_INTENT_CLIENT_SECRET";
export const SET_STRIPE_ERROR = "SET_STRIPE_ERROR";
export const ERROR_MENU = "ERROR_MENU";
export const LOADING_TABLE_BOOKER = "LOADING_TABLE_BOOKER";
export const ERROR_TABLE_BOOKER = "ERROR_TABLE_BOOKER";
export const SET_TABLE_BOOKER = "SET_TABLE_BOOKER";
export const SET_RESERVATIONS_HISTORY = "SET_RESERVATIONS_HISTORY";
export const LOADING_RESERVATIONS_HISTORY = "LOADING_RESERVATIONS_HISTORY";
export const SET_TABLE_RESERVATION = "SET_TABLE_RESERVATION";
export const SET_RESERVATION_RECAP_ID = "SET_RESERVATION_RECAP_ID";
export const SET_WEBSITE_SECTION = "SET_WEBSITE_SECTION";

import configGlobal from "../../config";

const axios = require("axios");
const instance = axios.create();
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    originalRequest._retry = true;

    if (error.response && error.response.status === 401 && error.response.data === "") {
      const data = {
        client_token: {
          grant_type: "client_credentials",
          client_id: configGlobal.client_id,
        },
      };

      const config = {
        headers: {
          "Content-type": "application/json",
          "Accept-language": localStorage.getItem("locale") || "fr",
        },
      };

      axios
        .post(`${configGlobal.api_url}/client_tokens`, data, config)
        .then((results) => {
          axios.defaults.headers["Authorization"] = `Bearer ${results.data.access_token}`;
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${results.data.access_token}`;
          localStorage.setItem("access_token", results.data.access_token);
          window.location.reload();
        });
    }

    return Promise.reject(error);
  }
);

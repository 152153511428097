import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as menuActions from "../../redux/actions/menuActions";
import * as cartActions from "../../redux/actions/cartActions";
import CartDetails from "./shared/CartDetails";
import { bindActionCreators } from "redux";
import OrderRecap from "./OrderRecap";
import Steps from "./shared/Steps";
import UnAuthorizedRestaurant from "./shared/UnAuthorizedRestaurant";

import Takable from "./shared/Takable";
import OrderConfirmation from "./shared/OrderConfirmation";
import OrderPayment from "./shared/OrderPayment";

function OrderValidation({
  cartActions,
  cart,
  actions,
  menu,
  total,
  takableInfo,
  success_order,
  validated_cart,
  validated_takeable,
  validated_total,
  orders_history,
  loading_orders_history,
  isProcessing,
  payment_intent_client_secret,
  stripeRequireAction,
  stripe_error,
}) {
  let { restaurant_id } = useParams();
  const [cart_dishes, setCartDishes] = useState([]);
  const [cart_formulas, setCartFormulas] = useState([]);
  const [takableValidated, setTakableValidated] = useState(false);
  const [takeType, setTakeType] = useState(false);
  const [commanderAddress, setCommanderAddress] = useState({});
  const [userInfo, setUserInfo] = useState();
  const [payment, setPayment] = useState("");

  const [step, setStep] = useState(1);

  const removeFormula = (index) => {
    cartActions.removeFormula(index);
  };
  const removeDish = (index) => {
    cartActions.removeDish(index);
  };
  const setCTakableInfo = (data) => {
    cartActions.setTakableInfo(data);
    setTakableValidated(true);
    setStep(2);
  };
  useEffect(() => {
    const id = restaurant_id.split("-")[0];
    actions.fetchRestaurant(id);
  }, [restaurant_id, actions]);
  useEffect(() => {
    if (menu && menu.can_take_orders) {
      const entry_id = menu.entry.id;
      setCartDishes([...cart.dishes.filter((x) => x.restaurantId === entry_id)]);
      setCartFormulas([...cart.formulas.filter((x) => x.restaurantId === entry_id)]);

      cartActions.calculateTotal(cart, menu);
    }
  }, [cart, menu, cartActions]);

  const sendCVerificationSms = (code, phone) => {
    if (process.env.NODE_ENV === "production") {
      cartActions.sendVerificationSms(code, phone);
    } else {
      console.log(code);
    }
  };

  const submitCashOrder = () => {
    const id = restaurant_id.split("-")[0];
    cartActions.submitCashOrder({
      userInfo: userInfo,
      commanderAddress: commanderAddress,
      cart: cart,
      takableInfo: takableInfo,
      total: total,
      entryId: id,
      payment: { payment_method: "Cash" },
    });
    setStep(3);
  };

  const stripePaymentMethodHandler = (payment) => {
    const id = restaurant_id.split("-")[0];
    cartActions.submitStripeOrder({
      userInfo: userInfo,
      commanderAddress: commanderAddress,
      cart: cart,
      takableInfo: takableInfo,
      total: total,
      entryId: id,
      payment: payment,
    });
  };

  const submitConfirmation = (data) => {
    setUserInfo(data);
    setStep(3);
  };

  if (success_order !== false) {
    return (
      <OrderRecap
        validated_cart={validated_cart}
        validated_takeable={validated_takeable}
        validated_total={validated_total}
        menu={menu}
      />
    );
  }

  const handleDateChange = (delivery_date, delivery_type) => {
    const id = restaurant_id.split("-")[0];
    cartActions.fetchOrdersHistory({ entryId: id, delivery_date, delivery_type });
  };

  if (menu && !menu.can_take_orders) {
    return <UnAuthorizedRestaurant />;
  }

  return (
    <Container>
      <Steps step={step} setStep={(theStep) => setStep(theStep)} />
      <Row>
        {step === 0 && (
          <Col md={12}>
            <CartDetails
              total={total}
              cart={cart}
              menu={menu}
              with_actions={true}
              removeFormula={removeFormula}
              removeDish={removeDish}
            />
            <div className="mt-3">
              <span className="btn-validate" onClick={() => setStep(2)}>
                Suivant
              </span>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        {step === 1 && (cart_dishes.length > 0 || cart_formulas.length > 0) && (
          <div className={takableValidated ? "takableValidated" : ""}>
            <Col md={12}>
              <Takable
                menu={menu}
                setTakableInfo={setCTakableInfo}
                handleDateChange={handleDateChange}
                orders_history={orders_history}
                loading_orders_history={loading_orders_history}
                setTakeType={(type) => setTakeType(type)}
                takeType={takeType}
                setCommanderAddress={(infos) => setCommanderAddress(infos)}
                total={total}
              />
            </Col>
          </div>
        )}
      </Row>
      <Row>
        {step === 2 && takableValidated && (
          <OrderConfirmation
            sendVerificationSms={sendCVerificationSms}
            submitConfirmation={submitConfirmation}
            takeType={takeType}
            commanderAddress={commanderAddress}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
          />
        )}
      </Row>
      <Row className="pb-3">
        {step === 3 && (
          <OrderPayment
            menu={menu}
            payment={payment}
            setPayment={(p) => setPayment(p)}
            stripePaymentMethodHandler={stripePaymentMethodHandler}
            isProcessing={isProcessing}
            payment_intent_client_secret={payment_intent_client_secret}
            stripeRequireAction={stripeRequireAction}
            total={total}
            stripe_error={stripe_error}
          />
          // use context for some of theses params
        )}
      </Row>

      <Row className="pb-3">
        {step === 3 && payment === "cash" && (
          <Col xs={12} lg={3} md={4} sm={6} className="flex-center">
            <button className="mt-2 mb-4-5 btn-validate" onClick={submitCashOrder}>
              Commander
            </button>
          </Col>
        )}
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    menu: state.menuReducer.menu,
    cart: state.cartReducer.cart,
    takableInfo: state.cartReducer.takableInfo,
    total: state.cartReducer.total,
    success_order: state.cartReducer.success_order,
    validated_cart: state.cartReducer.validated_cart,
    validated_takeable: state.cartReducer.validated_takeable,
    validated_total: state.cartReducer.validated_total,
    orders_history: state.cartReducer.orders_history,
    loading_orders_history: state.cartReducer.loading_orders_history,
    isProcessing: state.cartReducer.isProcessing,
    stripeRequireAction: state.cartReducer.stripeRequireAction,
    payment_intent_client_secret: state.cartReducer.payment_intent_client_secret,
    stripe_error: state.cartReducer.stripe_error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(menuActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderValidation);

import React from "react";
import Header from "./commons/Header";
import Footer from "./commons/Footer";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import { connect } from "react-redux";

function Layout({ generating_document }) {
  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar />
      <Header />
      <main>
        <Outlet />
        <Footer />
      </main>

      <CookieConsent
        location="bottom"
        buttonText="Ok pour moi"
        cookieName="myAwesomeCookieName2"
        style={{ color: "white", background: "#ffd42c" }}
        buttonStyle={{ color: "#000", fontSize: "14px", background: "white" }}
        expires={150}
      >
        <h3>Gestion des cookies</h3>
        <div style={{ fontSize: "14px", color: "black" }}>
          On veut rendre votre visite la plus agréable possible et on aimerait donc bien
          vous accompagner pendant celle-ci... C'est OK pour vous ?
        </div>
      </CookieConsent>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

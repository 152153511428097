import configGlobal from "../../config";
const axios = require("axios");

export const menuService = {
  fetchRestaurant,
};

function fetchRestaurant(id) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios.get(`${configGlobal.api_url}/restaurant_menu/${id}`, config);
}

import React, { useState, useRef } from "react";
import { Modal, Button, Form, Overlay, Popover } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import {
  faMinus,
  faPlus,
  faArrowLeft,
  faAllergies,
} from "@fortawesome/free-solid-svg-icons";

function Dish({ dish, additional_dishes, addDishToCart }) {
  const [show, setShow] = useState(false);
  const [animation, setAnimation] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setAnimation("");
  };

  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShowPopover(!showPopover);
    setTarget(event.target);
  };

  const variantsContainer = {
    added: {
      backgroundColor: ["#e6ee9c", "#f1f8e9", "#fff"],
      transition: {
        duration: 2,
        repeatType: "reverse",
      },
    },
    opened: {
      backgroundColor: ["#fff", "#ffecb3", "#fff"],
      transition: {
        duration: 2,
        repeatType: "reverse",
      },
    },
  };

  return (
    <motion.div
      variants={variantsContainer}
      animate={animation}
      whileHover={{
        scale: 1.01,
        boxShadow: "2px 1px 15px 0 #f2f2f2",
        borderRadius: "14px",
      }}
    >
      <div className="mb-3 dish">
        <div>
          {dish.dish_image ? (
            <img src={dish.dish_image} alt="dish preview" className="dish-image" />
          ) : (
            <img
              src="/images/placeholder/menu.png"
              alt="dish preview"
              className="dish-image"
            />
          )}
        </div>
        <div>
          <div>
            <div className="dish-label">
              <span>{dish.label}</span>
              {dish.allergens.length > 0 && (
                <div ref={ref}>
                  <span onClick={handleClick}>
                    <FontAwesomeIcon icon={faAllergies} color="#576c84" />
                  </span>

                  <Overlay
                    show={showPopover}
                    target={target}
                    placement="bottom"
                    container={ref}
                    containerPadding={20}
                  >
                    <Popover id="popover-contained">
                      <Popover.Header as="h3">Allergens</Popover.Header>
                      <Popover.Body>
                        {dish.allergens.map((al) => (
                          <div key={al.number}>
                            {al.number} - {al.title}
                          </div>
                        ))}
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              )}
            </div>
            <div className="dish-description">{dish.description}</div>
          </div>
          <div className="price-action">
            <div className="dish-price">{dish.price} €</div>
            <div className="dish-action" onClick={handleShow}>
              <FontAwesomeIcon icon={faPlus} color="#576c84" />
            </div>
          </div>
        </div>
      </div>

      <DishModal
        show={show}
        handleClose={handleClose}
        label={dish.label}
        additional_dishes={additional_dishes}
        dishId={dish.id}
        addDishToCart={addDishToCart}
        dish_price={dish.price}
        setAnimation={() => setAnimation("added")}
      />
    </motion.div>
  );
}

const schema = yup
  .object({
    note: yup.string(),
    quantity: yup.number().positive().min(1),
  })
  .required();

function DishModal({
  show,
  handleClose,
  label,
  additional_dishes,
  dishId,
  addDishToCart,
  dish_price,
  setAnimation,
}) {
  const { register, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    addDishToCart({ ...data, dishId: dishId });
    handleClose();
    setAnimation();
  };

  const variantsContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };
  const variantsItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      <form onSubmit={handleSubmit(onSubmit)} id={`hook-form-${dishId}`}>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* type spring tween inertia  */}
          <Modal.Header>
            <span onClick={handleClose}>
              <FontAwesomeIcon icon={faArrowLeft} color="#303733" />
            </span>
            <Modal.Title className="ml-2">{label}</Modal.Title>
            <span></span>
          </Modal.Header>
          <Modal.Body>
            <motion.div variants={variantsContainer} initial="hidden" animate="show">
              <div className="form-section">
                <motion.div variants={variantsItem} className="form-group mt-3 mb-3">
                  <label className="mb-2">Ajoutez une note pour ce produit </label>
                  <input className="form-control" id="note" {...register("note")} />
                </motion.div>

                <motion.div variants={variantsItem} className="form-group mt-3 mb-3">
                  <label className="mb-2">Suppléments </label>
                  {additional_dishes.map((ad) => (
                    <Form.Group
                      className="mb-3 checkbox-supplements"
                      controlId="formBasicCheckbox"
                      key={ad.id}
                    >
                      <Form.Check.Input
                        type="checkbox"
                        value={ad.id}
                        id={`inline-${ad.id}`}
                        {...register(`additional_dishes[]`)}
                      />
                      <Form.Check.Label htmlFor={`inline-${ad.id}`}>
                        <span>{ad.label}</span>
                        <span>{`${ad.price} €`}</span>
                      </Form.Check.Label>
                    </Form.Group>
                  ))}
                </motion.div>

                <motion.div
                  variants={variantsItem}
                  className="form-group quantity-group mt-3 mb-3"
                >
                  <div
                    className="quantity-action"
                    onClick={() =>
                      parseInt(getValues("quantity")) > 1 &&
                      setValue("quantity", parseInt(getValues("quantity")) - 1)
                    }
                  >
                    <FontAwesomeIcon icon={faMinus} color="#303733" />
                  </div>
                  <input
                    type="number"
                    className="quantity-input"
                    id="note"
                    min="1"
                    max="5"
                    defaultValue="1"
                    {...register("quantity")}
                  />
                  <div
                    className="quantity-action"
                    onClick={() =>
                      parseInt(getValues("quantity")) < 5 &&
                      setValue("quantity", parseInt(getValues("quantity")) + 1)
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} color="#303733" />
                  </div>
                  <div className="dish-price mt-1">
                    <span className="mr-2">Prix du plat </span>
                    <span>{dish_price}€</span>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <input
              type="submit"
              form={`hook-form-${dishId}`}
              className="btn btn-success"
              value="Ajouter au panier"
            />
          </Modal.Footer>
        </Modal>
      </form>
    </AnimatePresence>
  );
}

export default Dish;

import React from "react";

function UnAuthorizedRestaurant({ message }) {
  return (
    <div className="curved-background">
      <div className="curved-background__curved">
        <div className="text-center py-5">
          <div className="h1">Restaurant indisponible</div>
        </div>
      </div>
      <p className="p py-5 text-center">{message}</p>
    </div>
  );
}

export default UnAuthorizedRestaurant;

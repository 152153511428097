export default function tableBookerReducer(
  state = {
    table_booker: false,
    table_reservation: false,
    reservation_recap_id: false,
    loading_table_booker: false,
    error_table_booker: false,
    reservations_history: [],
    loading_reservations_history: false,
  },
  action
) {
  switch (action.type) {
    case "SET_TABLE_BOOKER":
      return {
        ...state,
        table_booker: action.table_booker,
      };
    case "SET_TABLE_RESERVATION":
      return {
        ...state,
        table_reservation: action.table_reservation,
      };
    case "SET_RESERVATION_RECAP_ID":
      return {
        ...state,
        reservation_recap_id: action.reservation_recap_id,
      };
    case "LOADING_TABLE_BOOKER":
      return {
        ...state,
        loading_table_booker: action.loading_table_booker,
      };
    case "ERROR_TABLE_BOOKER":
      return {
        ...state,
        error_table_booker: action.error_table_booker,
      };
    case "SET_RESERVATIONS_HISTORY":
      return {
        ...state,
        reservations_history: action.reservations_history,
      };
    case "LOADING_RESERVATIONS_HISTORY":
      return {
        ...state,
        loading_reservations_history: action.loading_reservations_history,
      };
    default:
      return state;
  }
}

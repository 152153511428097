import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as tableBookerActions from "../../redux/actions/tableBookerActions";
import { bindActionCreators } from "redux";
import { addMinutes } from "date-fns";
import Moment from "react-moment";

function ReservationRecap({ actions, table_reservation }) {
  let { reservation_id } = useParams();

  useEffect(() => {
    actions.fetchTableReservation(reservation_id);
  }, [reservation_id, actions]);

  return (
    <Container>
      <Row className="pt-2 mb-3">
        <Col md={12}>
          <div className="order-recap-title">
            Nous avons enregistré votre réservation.
          </div>
        </Col>
        <Col md={12}>
          <div className="order-recap-sub-title my-3">
            Voici le récapitulatif de votre réservation :
          </div>
          <hr />
        </Col>

        <Col md={12} className="mt-3">
          <span className="delivery-time">Réservation faire par : </span>
          <span className="reservation-info">{table_reservation.name}</span>
        </Col>
        <Col md={12} className="my-1">
          <span className="delivery-time">Réservation prévue le</span>
          <Moment className="delivery-time date" format="DD/MM/YYYY">
            {table_reservation.reservation_date}
          </Moment>
          <span className="delivery-time">à</span>
          <Moment className="delivery-time date" format="HH:mm">
            {table_reservation.reservation_date}
          </Moment>
        </Col>
        <Col md={12} className="mb-3">
          <span className="delivery-time">Nombre de personne attendu : </span>
          <span className="reservation-info">{table_reservation.number_of_people}</span>
        </Col>

        <Col xs={12} className="mb-1">
          <hr />
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    table_reservation: state.tableBookerReducer.table_reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(tableBookerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationRecap);

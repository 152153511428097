import * as types from "./actionTypes";
import { menuService } from "../services/menuService";

export function loadingMenu(loading_menu) {
  return { type: types.LOADING_MENU, loading_menu };
}
export function setErrorMenu(error_menu) {
  return { type: types.ERROR_MENU, error_menu };
}

export function setWebsiteSection(website_section) {
  return { type: types.SET_WEBSITE_SECTION, website_section };
}

export function fetchRestaurant(id) {
  return (dispatch) => {
    dispatch(loadingMenu(true));
    menuService.fetchRestaurant(id).then(
      (res) => {
        dispatch(setMenu(res.data.menu));
        dispatch(loadingMenu(false));
        dispatch(setErrorMenu(false));
        dispatch(setWebsiteSection("menu"));
      },
      (error) => {
        dispatch(loadingMenu(false));
        dispatch(setErrorMenu(true));
      }
    );
  };
}

export function setMenu(menu) {
  return { type: types.SET_MENU, menu };
}

import React from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import Formula from "./formula/Formula";
import Dish from "./card/Dish";
import { AnimatePresence, motion } from "framer-motion";

function MenuList({ menu, addDishToCart, addFormulaToCart }) {
  const variantsContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.01,
      },
    },
  };
  const variantsItem = {
    hidden: { opacity: 0, y: -50 },
    show: { opacity: 1, y: 0 },
  };
  return (
    <AnimatePresence>
      <motion.div
        className="mt-3"
        variants={variantsContainer}
        initial="hidden"
        animate="show"
      >
        {menu.menu_categories.length > 0 && <div className="card-title py-3">Carte</div>}
        {menu.menu_categories.map((mc) => (
          <div key={mc.id}>
            {mc.category_image === false ? (
              <></>
            ) : (
              <div
                className="category-image"
                style={{ backgroundImage: `url(${mc.category_image})` }}
              ></div>
            )}

            <Row className="menu-category" id={`category-${mc.id}`} key={mc.id}>
              <Accordion defaultActiveKey={mc.id} className="mb-3">
                <Accordion.Item eventKey={mc.id}>
                  <Accordion.Header>
                    <div className="menu-title">{mc.title}</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {mc.dishes.map((dish) => (
                        <motion.div
                          variants={variantsItem}
                          key={dish.id}
                          className="col-xs-12 col-md-12 col-lg-6"
                          transition={{ type: "spring", bounce: 0 }}
                        >
                          <Dish
                            dish={dish}
                            additional_dishes={mc.additional_dishes}
                            addDishToCart={addDishToCart}
                          />
                        </motion.div>
                      ))}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>
          </div>
        ))}
        {menu.formulas.length > 0 && (
          <Row className="mb-3">
            <Col md={12} className="mb-3">
              <div className="menu-title">Les menus</div>
            </Col>
          </Row>
        )}

        <Row>
          {menu.formulas.map((formula) => (
            <Col
              xs={12}
              md={12}
              lg={6}
              className="menu-category"
              id={`formula-${formula.id}`}
              key={formula.id}
            >
              <Formula formula={formula} addFormulaToCart={addFormulaToCart} />
            </Col>
          ))}
        </Row>
      </motion.div>
    </AnimatePresence>
  );
}

export default MenuList;

import React from "react";
import { Container, Row, Col, Placeholder } from "react-bootstrap";
import { motion } from "framer-motion";

function LoadingMenu() {
  const variantsContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const variantsItem = {
    hidden: { opacity: 0, scale: 1.04 },
    show: { opacity: 1, scale: 1 },
  };
  return (
    <Container>
      <Row className="header-placeholder py-3">
        <Col className="mt-3">
          <div>
            <Placeholder xs={6} bg="secondary" size="sm" />
          </div>
          <Placeholder xs={3} bg="secondary" size="sm" />
        </Col>
      </Row>
      <motion.div
        className="my-5 row"
        variants={variantsContainer}
        initial="hidden"
        animate="show"
      >
        <Col xs={12}>
          {["first", "second", "third"].map((cd) => (
            <motion.div
              variants={variantsItem}
              transition={{ type: "spring", bounce: 0 }}
              className={`col-xs-12 mb-3 dish loading ${cd}`}
              key={cd}
            >
              <div>
                <Placeholder xs={12} className="big-placeholder" bg="info" />
              </div>
              <div>
                <div>
                  <Placeholder xs={6} bg="secondary" size="sm" />
                </div>
                <div className="price-action">
                  <Placeholder xs={8} size="sm" />
                </div>
                <div className="mt-2">
                  <Placeholder xs={3} bg="secondary" size="sm" />
                </div>
              </div>
            </motion.div>
          ))}
        </Col>
      </motion.div>
    </Container>
  );
}

export default LoadingMenu;

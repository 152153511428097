const axios = require("axios");

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled ? false : true;
};

axios.interceptors.request.use(
  function (config) {
    if (isHandlerEnabled(config))
      config.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

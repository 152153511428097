import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

function Footer({ menu }) {
  return (
    <div className="p-5 footerContainer">
      {menu && menu.can_take_orders && (
        <Container>
          <Row className="pb-3 text-center">
            <Col xs={12} className="footer-title">
              Contacter Restaurant
            </Col>
            <Col xs={12} className="footer-title">
              {menu?.entry.title}
            </Col>
          </Row>
          <Row className="pb-3 text-center">
            {menu.entry.complete_number != "false" && (
              <Col xs={12} className="footer-info">
                <span>T : </span>
                <a href={`tel:${menu.entry.complete_number}`}>
                  {menu.entry.complete_number}
                </a>
              </Col>
            )}
          </Row>
          <Row className="pb-3 text-center">
            <Col xs={12} className="footer-info">
              <a href={menu.entry.url}>{menu.entry.url}</a>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    menu: state.menuReducer.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

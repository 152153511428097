import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { subDays, getDay, addMinutes, addHours } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence } from "framer-motion";
import { Form } from "react-bootstrap";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import { Link } from "react-router-dom";
import Moment from "react-moment";

registerLocale("fr", fr);

function Takable({
  menu,
  setTakableInfo,
  orders_history,
  handleDateChange,
  loading_orders_history,
  setTakeType,
  takeType,
  setCommanderAddress,
  total,
}) {
  const [startDate, setStartDate] = useState(null);
  const [startHour, setStartHour] = useState(null);
  const [showCheckDistance, setShowCheckDistance] = useState(false);
  const [takableHours, setTakableHours] = useState(false);
  const [readyToValidate, setReadyToValidate] = useState(false);
  const [verifiedDistance, setVerifiedDistance] = useState(false);

  const openDays = (date) => {
    const day = getDay(date);
    var days = menu[takeType].each_business_days;

    var listDays = days.some((dd) => {
      return (
        (dd.line.business_days.length > 0 && dd.line.day === day) ||
        (day === 0 && dd.line.day === 7)
      );
    });

    return listDays;
  };
  const filterPassedTime = (time) => {
    const currentTime = addMinutes(new Date(), menu[takeType].processing_time).getTime();
    const selectedTimeList = new Date(time).getTime();

    const slot_date = orders_history.some((date) => {
      return new Date(date).getTime() === new Date(time).getTime();
    });

    var openTime = takableHours.some((th) => {
      var from = convertDate(th.from);
      var to = convertDate(th.to);
      return from <= selectedTimeList && selectedTimeList <= to;
    });

    return openTime && currentTime < selectedTimeList && !slot_date;
  };
  const convertDate = (time) => {
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const day = startDate.getDate();

    const date = new Date(time);

    const hour = date.getUTCHours();
    const minutes = date.getMinutes();

    return new Date(year, month, day, hour, minutes).getTime();
  };
  const changeTakeType = (e) => {
    setTakeType(e.target.value);
    setTakableHours(null);
    setStartDate(null);
  };
  const onChangeDate = (date) => {
    setReadyToValidate(false);
    const day = getDay(date);

    setStartDate(date);
    setStartHour(date);

    setTakableHours(menu[takeType].each_business_days[day - 1].line.business_days);

    handleDateChange(addHours(date, 12), takeType);
  };
  const changeTime = (date) => {
    setStartHour(date);
    setReadyToValidate(true);
  };
  const validateTakableInfo = () => {
    setTakableInfo({ startHour, takeType });
  };

  useEffect(() => {
    setShowCheckDistance(takeType === "take_home" ? true : false);
  }, [takeType]);

  const hasTakableHours = takableHours && takableHours.length > 0;
  return (
    <div className="mb-4-5">
      <div className="form-group mt-3 mb-3">
        <label className="mb-2">Choix du mode de livraison </label>
        <div className="radio-container">
          {menu.can_take_away && (
            <Form.Group className="mb-3 delivery-radios" controlId="formBasicCheckbox">
              <span className="delivery-picto">
                <img src="/images/pictos/take_away.png" alt="delivery picto" />
              </span>
              <Form.Check.Label htmlFor={`inline-take-away`}>
                <span>Retrait sur place</span>
              </Form.Check.Label>
              <Form.Check.Input
                name="takable"
                type="radio"
                value="take_away"
                id={`inline-take-away`}
                onChange={changeTakeType}
              />
            </Form.Group>
          )}

          {menu.can_take_home && menu.take_home.minimum_for_delivery > total && (
            <Link
              to={`/${menu?.entry.id}-${menu?.entry.title}`}
              className="delivery-not-possible"
            >
              <div className="dnp-message">
                <div>
                  Le montant minimum pour la livraison est de{" "}
                  {menu.take_home.minimum_for_delivery}€.
                </div>
                <div>Cliquez pour ajouter à la commande. </div>
              </div>
            </Link>
          )}

          {menu.can_take_home && menu.take_home.minimum_for_delivery <= total && (
            <Form.Group className="mb-3 delivery-radios" controlId="formBasicCheckbox">
              <span className="delivery-picto">
                <img src="/images/pictos/take_home.png" alt="delivery picto" />
              </span>
              <Form.Check.Label htmlFor={`inline-take-home`}>
                <span>Livraison à domicile</span>
              </Form.Check.Label>
              <Form.Check.Input
                name="takable"
                type="radio"
                value="take_home"
                id={`inline-take-home`}
                onChange={changeTakeType}
              />
            </Form.Group>
          )}
        </div>
      </div>
      {((takeType === "take_home" && verifiedDistance) || takeType === "take_away") && (
        <>
          <div className="mb-3">
            <label className="mb-2">Saisissez la Date</label>
            <DatePicker
              locale="fr"
              className="mb-2 datepicker-input"
              selected={startDate}
              onChange={(date) => onChangeDate(date)}
              minDate={subDays(new Date(), 0)}
              filterDate={openDays}
              dateFormat="dd/M/yyyy"
            />
          </div>

          {loading_orders_history && <p>loading</p>}
          {hasTakableHours && orders_history && !loading_orders_history && (
            <div className="pb-2">
              <label className="mb-2">Saisissez l'heure</label>
              <DatePicker
                className="mb-2 datepicker-input"
                selected={startHour}
                onChange={(date) => changeTime(date)}
                filterTime={filterPassedTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={menu[takeType].take_delay}
                timeCaption="Selectionnez"
                timeFormat={`HH:mm ${
                  takeType === "take_home" ? ` (+${menu[takeType].take_delay})` : ""
                }`}
                dateFormat="HH:mm"
              />
            </div>
          )}
          {readyToValidate && hasTakableHours && takeType === "take_home" && (
            <Col md={12} className="pb-3">
              <span className="delivery-time">Livraison de la commande prévue le</span>
              <Moment className="delivery-time date" format="DD/MM/YYYY">
                {startHour}
              </Moment>
              <span className="delivery-time">entre</span>
              <Moment className="delivery-time date" format="HH:mm">
                {startHour}
              </Moment>
              <span className="delivery-time">et</span>
              <Moment className="delivery-time date" format="HH:mm">
                {addMinutes(startHour, menu[takeType].take_delay)}
              </Moment>
            </Col>
          )}
          {readyToValidate && hasTakableHours && (
            <Col xs={12} lg={3} md={4} sm={6} className="flex-center">
              <div className="mb-2 btn-validate " onClick={validateTakableInfo}>
                Valider
              </div>
            </Col>
          )}
        </>
      )}
      {menu.can_take_home && (
        <DistanceCheckModal
          menu={menu}
          show={showCheckDistance}
          setVerifiedDistance={(verified) => setVerifiedDistance(verified)}
          handleClose={() => setShowCheckDistance(false)}
          setCommanderAddress={setCommanderAddress}
        />
      )}
    </div>
  );
}

function DistanceCheckModal({
  show,
  handleClose,
  menu,
  setVerifiedDistance,
  setCommanderAddress,
}) {
  const [farDistance, setFarDistance] = useState(false);
  const ADDRESS_NUMBER = 0;
  const ADDRESS_LINE = 1;
  const LOCALITY = 2;
  const ZIP_CODE = 5;
  const COUNTRY = 4;
  const schema = yup
    .object({
      address_number: yup.string().required("Champ requis !"),
      address_line: yup.string().required("Champ requis !"),
      zip_code: yup.string().required("Champ requis !"),
      locality: yup.string().required("Champ requis !"),
      country: yup.string().required("Champ requis !"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    setCommanderAddress(data);
    setVerifiedDistance(true);
    handleClose();
  };

  const rad = (x) => {
    return (x * Math.PI) / 180;
  };

  const getDistance = (p1, p2) => {
    const R = 6378; // Earth’s mean radius in Km
    let dLat = rad(p2.lat - p1.lat);
    let dLong = rad(p2.lng - p1.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d; // returns the distance in Km
  };

  return (
    <AnimatePresence>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <span onClick={handleClose}>
            <FontAwesomeIcon icon={faArrowLeft} color="#303733" />
          </span>
          <Modal.Title className="ml-2">Vérification de la livraison</Modal.Title>
          <span></span>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} className="admin-form">
            <Row>
              <Col xs={12}>
                <div className="form-group mb-3">
                  <Autocomplete
                    apiKey={process.env["REACT_APP_GOOGLE_MAPS_API_KEY"]}
                    style={{
                      width: "100%",
                      background: "#e8ecf2",
                      height: "60px",
                      borderRadius: "14px",
                      border: "1px solid #00d793",
                      padding: "10px",
                    }}
                    onPlaceSelected={(place) => {
                      const distance = getDistance(
                        {
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        },
                        {
                          lat: parseFloat(menu.entry.latitude),
                          lng: parseFloat(menu.entry.longitude),
                        }
                      );

                      if (menu.take_home.distance >= distance) {
                        setValue(
                          "address_number",
                          place.address_components[ADDRESS_NUMBER].long_name
                        );
                        setValue(
                          "address_line",
                          place.address_components[ADDRESS_LINE].long_name
                        );
                        setValue(
                          "locality",
                          place.address_components[LOCALITY].long_name
                        );
                        setValue(
                          "zip_code",
                          place.address_components[ZIP_CODE].long_name
                        );
                        setValue("country", place.address_components[COUNTRY].long_name);
                        setFarDistance(false);
                      } else {
                        setVerifiedDistance(false);
                        setFarDistance(true);
                      }
                    }}
                    options={{
                      types: ["geocode"],
                      componentRestrictions: { country: "lu" },
                    }}
                  />
                  <div className="max-distance">
                    Le rayon maximum pour la livraison à domicile est de{" "}
                    {menu.take_home.distance} Km.
                  </div>
                  {farDistance && (
                    <p className="mt-3 far-distance">
                      Ce restaurant ne livre pas à votre adresse
                    </p>
                  )}
                </div>
              </Col>
              <Col xs={4}>
                <div className="form-group mb-3">
                  <label className="mb-2">N° de rue</label>
                  <input
                    disabled
                    className="form-control"
                    id="name"
                    {...register("address_number")}
                  />
                  <small className="form-text text-muted">
                    {errors.address_number?.message}
                  </small>
                </div>
              </Col>
              <Col xs={8}>
                <div className="form-group mb-3">
                  <label className="mb-2">Adresse</label>
                  <input
                    disabled
                    className="form-control"
                    id="name"
                    {...register("address_line")}
                  />
                  <small className="form-text text-muted">
                    {errors.address_line?.message}
                  </small>
                </div>
              </Col>
              <Col xs={5}>
                <div className="form-group mb-3">
                  <label className="mb-2">Code postal</label>
                  <input
                    disabled
                    className="form-control"
                    id="name"
                    {...register("zip_code")}
                  />
                  <small className="form-text text-muted">
                    {errors.zip_code?.message}
                  </small>
                </div>
              </Col>
              <Col xs={7}>
                <div className="form-group mb-3">
                  <label className="mb-2">Localité</label>
                  <input
                    disabled
                    className="form-control"
                    id="name"
                    {...register("locality")}
                  />
                  <small className="form-text text-muted">
                    {errors.locality?.message}
                  </small>
                </div>
              </Col>

              <div className="form-group mb-3">
                <label className="mb-2">Pays</label>
                <input
                  disabled
                  className="form-control"
                  id="name"
                  {...register("country")}
                />
                <small className="form-text text-muted">{errors.country?.message}</small>
              </div>
            </Row>
            <Row>
              <Col xs={6}>
                <button type="submit" className="mb-2 btn-validate">
                  Valider
                </button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </AnimatePresence>
  );
}

export default Takable;

import * as types from "./actionTypes";
import { tableBookerService } from "../services/tableBookerService";

export function loadingTableBooker(loading_table_booker) {
  return { type: types.LOADING_TABLE_BOOKER, loading_table_booker };
}
export function setErrorTableBooker(error_table_booker) {
  return { type: types.ERROR_TABLE_BOOKER, error_table_booker };
}

export function setReservationsHistory(reservations_history) {
  return { type: types.SET_RESERVATIONS_HISTORY, reservations_history };
}

export function loadingReservationsHistory(loading_reservations_history) {
  return { type: types.LOADING_RESERVATIONS_HISTORY, loading_reservations_history };
}

export function setWebsiteSection(website_section) {
  return { type: types.SET_WEBSITE_SECTION, website_section };
}

export function fetchTableBooker(id) {
  return (dispatch) => {
    dispatch(loadingTableBooker(true));
    tableBookerService.fetchTableBooker(id).then(
      (res) => {
        dispatch(setWebsiteSection("table_booker"));

        dispatch(setTableBooker(res.data.table_booker));
        dispatch(loadingTableBooker(false));
        dispatch(setErrorTableBooker(false));
      },
      (error) => {
        console.log("error here");
        dispatch(loadingTableBooker(false));
        dispatch(setErrorTableBooker(true));
      }
    );
  };
}

export function fetchReservationsHistory(data) {
  return (dispatch) => {
    dispatch(loadingReservationsHistory(true));
    tableBookerService.fetchReservationsHistory(data).then(
      (res) => {
        dispatch(loadingReservationsHistory(false));
        dispatch(setReservationsHistory(res.data.reservations_history));
      },
      (error) => {
        dispatch(loadingReservationsHistory(false));
        console.log(error);
      }
    );
  };
}

export function fetchTableReservation(reservation_id) {
  return (dispatch) => {
    tableBookerService.fetchTableReservation(reservation_id).then(
      (res) => {
        dispatch(setTableReservation(res.data.table_reservation));
      },
      (error) => {
        dispatch(loadingReservationsHistory(false));
        console.log(error);
      }
    );
  };
}

export function bookTable(data) {
  return (dispatch) => {
    tableBookerService.bookTable(data).then(
      (res) => {
        dispatch(redirect_to_reservation_recap(res.data.id));
      },
      (error) => {
        dispatch(loadingReservationsHistory(false));
        console.log(error);
      }
    );
  };
}

export function setTableBooker(table_booker) {
  return { type: types.SET_TABLE_BOOKER, table_booker };
}
export function setTableReservation(table_reservation) {
  return { type: types.SET_TABLE_RESERVATION, table_reservation };
}
export function redirect_to_reservation_recap(reservation_recap_id) {
  return { type: types.SET_RESERVATION_RECAP_ID, reservation_recap_id };
}

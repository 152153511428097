const initlocale = window.localStorage.getItem("locale")
  ? window.localStorage.getItem("locale")
  : "fr";

export default function localeReducer(state = { locale: initlocale }, action) {
  switch (action.type) {
    case "CHANGE_LOCALE":
      return {
        ...state,
        locale: action.locale,
      };
    default:
      return state;
  }
}

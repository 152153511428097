import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { getDay, addHours } from "date-fns";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as tableBookerActions from "../../redux/actions/tableBookerActions";
import { bindActionCreators } from "redux";
import Header from "./table_booker/Header";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import PhoneInput from "react-phone-input-2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import UnAuthorizedRestaurant from "./shared/UnAuthorizedRestaurant";
import LoadingMenu from "./shared/LoadingMenu";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";

registerLocale("fr", fr);

function TableBooker({
  actions,
  table_booker,
  loading_table_booker,
  error_table_booker,
  loading_reservations_history,
  reservations_history,
  reservation_recap_id,
}) {
  let { restaurant_id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startHour, setStartHour] = useState(null);
  const [numberOfPeople, setNumberOfPeople] = useState(0);
  const [takableHours, setTakableHours] = useState(false);
  const [readyToValidate, setReadyToValidate] = useState(false);
  let navigate = useNavigate();

  const can_pick_day = numberOfPeople > 0 && numberOfPeople <= table_booker.slot;
  const hasTakableHours = takableHours && takableHours.length > 0;
  const can_pick_time =
    startDate !== null &&
    can_pick_day &&
    hasTakableHours &&
    reservations_history &&
    !loading_reservations_history;
  const can_validate = readyToValidate && can_pick_time;

  const onChangeDate = (date) => {
    setReadyToValidate(false);
    setStartDate(date);
    const id = restaurant_id.split("-")[0];

    const day = getDay(date);

    setStartDate(date);
    setStartHour(date);

    setTakableHours(table_booker.each_business_days[day - 1].line.business_days);

    actions.fetchReservationsHistory({
      entryId: id,
      reservation_date: addHours(date, 12),
      number_of_people: numberOfPeople,
    });
  };

  const changeTime = (date) => {
    setStartHour(date);
    setReadyToValidate(true);
  };

  const filterPassedTime = (time) => {
    const currentTime = new Date().getTime();
    const selectedTimeList = new Date(time).getTime();

    const slot_date = reservations_history.some((date) => {
      return new Date(date).getTime() === new Date(time).getTime();
    });

    var openTime = takableHours.some((th) => {
      var from = convertDate(th.from);
      var to = convertDate(th.to);
      return from <= selectedTimeList && selectedTimeList <= to;
    });

    return openTime && currentTime < selectedTimeList && !slot_date;
  };

  const convertDate = (time) => {
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const day = startDate.getDate();

    const date = new Date(time);

    const hour = date.getUTCHours();
    const minutes = date.getMinutes();

    return new Date(year, month, day, hour, minutes).getTime();
  };

  const openDays = (date) => {
    const day = getDay(date);
    var days = table_booker.each_business_days;

    var listDays = days.some((dd) => {
      return dd.line.day === day && dd.line.business_days.length > 0;
    });

    return listDays;
  };

  const bookTable = (data) => {
    actions.bookTable({
      ...data,
      reservation_date: startHour,
      number_of_people: numberOfPeople,
      entryId: restaurant_id.split("-")[0],
    });
  };

  const handlePeopleChange = (e) => {
    setNumberOfPeople(e.target.value);
    setStartDate(null);
  };

  useEffect(() => {
    const id = restaurant_id.split("-")[0];
    actions.fetchTableBooker(id);
  }, [restaurant_id, actions]);

  useEffect(() => {
    if (reservation_recap_id) {
      navigate(`/table_reservation/${reservation_recap_id}`);
    }
  }, [reservation_recap_id, navigate]);

  if ((table_booker && !table_booker.can_book_table) || error_table_booker) {
    return (
      <UnAuthorizedRestaurant message="Ce restaurant n'utilise plus notre outil de résérvation en ligne" />
    );
  }
  if (loading_table_booker) {
    return <LoadingMenu />;
  }

  return (
    <>
      {false && (
        <Header
          setShowModal={(show) => setShowModal(show)}
          showModal={showModal}
          table_booker={table_booker}
        />
      )}
      {table_booker && (
        <Container>
          <Row className="my-5">
            <Col xs={12}>
              <div className="mb-3">
                <label className="mb-3">
                  Saisissez le nombre de personne
                  <small className={numberOfPeople > table_booker.slot ? "over" : ""}>
                    max : {table_booker.slot} personnes
                  </small>
                </label>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    min={1}
                    max={table_booker.slot}
                    onChange={handlePeopleChange}
                    value={numberOfPeople}
                  />
                </div>
              </div>
            </Col>
            {can_pick_day && (
              <Col xs={12}>
                <div className="mb-3">
                  <label className="mb-2">Saisissez la Date</label>
                  <DatePicker
                    locale="fr"
                    className="mb-2 datepicker-input"
                    selected={startDate}
                    onChange={(date) => onChangeDate(date)}
                    minDate={new Date()}
                    filterDate={openDays}
                    dateFormat="dd/M/yyyy"
                    excludeDateIntervals={table_booker.excluded_date_ranges.map((d) => ({
                      start: new Date(d.start),
                      end: new Date(d.end),
                    }))}
                  />
                </div>
              </Col>
            )}

            {loading_reservations_history && <p>loading</p>}

            {can_pick_time && (
              <div className="pb-2">
                <label className="mb-2">Saisissez l'heure</label>
                <DatePicker
                  className="mb-2 datepicker-input"
                  selected={startHour}
                  onChange={(date) => changeTime(date)}
                  filterTime={filterPassedTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={table_booker.booking_interval}
                  timeCaption="Selectionnez"
                  timeFormat={`HH:mm`}
                  dateFormat="HH:mm"
                />
              </div>
            )}
            {can_validate && (
              <Col xs={12} lg={3} md={4} sm={6} className="flex-center">
                <div
                  className="mb-2 btn-validate "
                  onClick={() => setShowValidationModal(true)}
                >
                  Valider
                </div>
              </Col>
            )}

            <ValidationModal
              show={showValidationModal}
              handleClose={() => setShowValidationModal(false)}
              bookTable={bookTable}
            />
          </Row>
        </Container>
      )}
    </>
  );
}

function ValidationModal({ show, handleClose, bookTable }) {
  const schema = yup
    .object({
      name: yup.string().required(),
      phone: yup.string().required(),
      email: yup.string().email().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    bookTable(data);
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <span onClick={handleClose}>
          <FontAwesomeIcon icon={faArrowLeft} color="#303733" />
        </span>
        <Modal.Title className="ml-2">Vérification de la livraison</Modal.Title>
        <span></span>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="admin-form">
          <Row className="pt-3 mb-2">
            <Col md={12}>
              <div className="form-group mb-3">
                <label className="mb-2">Votre nom</label>
                <input className="form-control" id="name" {...register("name")} />
                <small className="form-text text-muted">{errors.name?.message}</small>
              </div>
              <div className="form-group mb-3">
                <label className="mb-2">Votre téléphone</label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      enableSearch
                      country={"lu"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />

                <small className="form-text text-muted">{errors.phone?.message}</small>
              </div>
              <div className="form-group mb-3">
                <label className="mb-2">Votre e-mail</label>
                <input className="form-control" id="email" {...register("email")} />
                <small className="form-text text-muted">{errors.email?.message}</small>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={3} md={4} sm={6} className="flex-center">
              <button type="submit" className="mt-2 mb-4-5 btn-validate">
                Envoyer la demande
              </button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    table_booker: state.tableBookerReducer.table_booker,
    reservations_history: state.tableBookerReducer.reservations_history,
    loading_reservations_history: state.tableBookerReducer.loading_reservations_history,
    loading_table_booker: state.tableBookerReducer.loading_table_booker,
    error_table_booker: state.tableBookerReducer.error_table_booker,
    reservation_recap_id: state.tableBookerReducer.reservation_recap_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(tableBookerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableBooker);

import React from "react";
import { Col } from "react-bootstrap";
import PaymentForm from "./payment/PaymentForm";

function OrderPayment({
  menu,
  payment,
  setPayment,
  stripePaymentMethodHandler,
  isProcessing,
  payment_intent_client_secret,
  stripeRequireAction,
  total,
  stripe_error,
}) {
  return (
    <>
      {menu.accept_cash && (
        <Col xs={12} md={6}>
          <div
            className={`payment-mode ${payment === "cash" ? "active" : ""}`}
            onClick={() => setPayment("cash")}
          >
            <span className="cash-picto">
              <img src="/images/pictos/cash.png" alt="cash picto" />
            </span>
            <span>Payer sur place</span>
          </div>
        </Col>
      )}
      {menu.accept_card && (
        <Col xs={12} md={6}>
          <div
            className={`payment-mode ${payment === "card" ? "active" : ""}`}
            onClick={() => setPayment("card")}
          >
            <span className="onligne-picto">
              <img src="/images/pictos/card.png" alt="cash picto" />
            </span>
            <span>Payer en ligne</span>
          </div>
        </Col>
      )}

      {payment === "card" && (
        <Col xs={12} md={12}>
          <PaymentForm
            stripePaymentMethodHandler={stripePaymentMethodHandler}
            isProcessing={isProcessing}
            payment_intent_client_secret={payment_intent_client_secret}
            stripeRequireAction={stripeRequireAction}
            total={total}
            stripe_error={stripe_error}
            public_key={menu.stripe_payment.public_key}
          />
        </Col>
      )}
    </>
  );
}

export default OrderPayment;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./componants/Layout";
import NoMatch from "./componants/NoMatch";
import { IntlProvider } from "react-intl";
import locale_en from "./locales/en";
import locale_fr from "./locales/fr";
import { connect } from "react-redux";
import ScrollToTop from "./componants/commons/ScrollToTop";

import RestaurantMenu from "./componants/static/RestaurantMenu";
import OrderValidation from "./componants/static/OrderValidation";
import TableBooker from "./componants/static/TableBooker";
import ReservationRecap from "./componants/static/ReservationRecap";

const messages = {
  en: locale_en,
  fr: locale_fr,
};

function App(props) {
  return (
    <IntlProvider locale={props.locale} messages={messages[props.locale]}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<NoMatch />} />
            <Route path=":restaurant_id/">
              <Route index element={<RestaurantMenu />} />
              <Route path="orderValidation" element={<OrderValidation />} />
            </Route>
            <Route path="table_booker/:restaurant_id/">
              <Route index element={<TableBooker />} />
            </Route>
            <Route path="table_reservation/:reservation_id/">
              <Route index element={<ReservationRecap />} />
            </Route>
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Router>
    </IntlProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.localeReducer.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // actions: bindActionCreators(coursesActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

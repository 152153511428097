import * as types from "./actionTypes";
import { cartService } from "../services/cartService";

export function addDishToCart(dish) {
  return { type: types.ADD_DISH_TO_CART, dish };
}
export function successOrder(success_order) {
  return { type: types.SUCCESS_ORDER, success_order };
}
export function setIsProcessing(isProcessing) {
  return { type: types.SET_IS_PROCESSING, isProcessing };
}
export function stripeRequireAction(stripeRequireAction) {
  return { type: types.STRIPE_REQUIRE_ACTION, stripeRequireAction };
}
export function setPaymentIntentClientSecret(payment_intent_client_secret) {
  return { type: types.SET_PAYMENT_INTENT_CLIENT_SECRET, payment_intent_client_secret };
}
export function setStripeError(stripe_error) {
  return { type: types.SET_STRIPE_ERROR, stripe_error };
}

export function validatedCart(validated_cart) {
  return { type: types.VALIDATED_CART, validated_cart };
}
export function validatedTakeable(validated_takeable) {
  return { type: types.VALIDATED_TAKEABLE, validated_takeable };
}
export function validatedTotal(validated_total) {
  return { type: types.SET_VALIDATED_TOTAL, validated_total };
}
export function addFormulaToCart(formula) {
  return { type: types.ADD_FORMULA_TO_CART, formula };
}
export function removeFormula(formula_index) {
  return { type: types.REMOVE_FORMULA_FROM_CART, formula_index };
}
export function removeDish(dish_index) {
  return { type: types.REMOVE_DISH_FROM_CART, dish_index };
}
export function removeFromCart(productId) {
  return { type: types.REMOVE_FROM_CART, productId };
}
export function resetCart() {
  return { type: types.RESET_CART };
}
export function resetTakableInfo() {
  return { type: types.RESET_TAKABLE_INFO };
}
export function loadSavedCart() {
  const savedCart = localStorage.getItem("menuCart") || [];
  return { type: types.LOAD_CART, cart: savedCart };
}
export function setTakableInfo(takableInfo) {
  return { type: types.SET_TAKABLE_INFO, takableInfo };
}
export function calculateTotal(cart, menu) {
  let totalDishes = 0;
  let totalFormulas = 0;

  const entry_id = menu.entry.id;

  const cart_dishes = [...cart.dishes.filter((x) => x.restaurantId === entry_id)];
  const cart_formulas = [...cart.formulas.filter((x) => x.restaurantId === entry_id)];

  if (cart_dishes.length > 0) {
    totalDishes = cart_dishes.reduce(
      (totalDish, dish) =>
        totalDish +
        dish.quantity *
          (dishPrice(menu.dishes, dish.dishId) +
            calculate_supplements(menu.additional_dishes, dish.additional_dishes)),
      0
    );
  }
  if (cart_formulas.length > 0) {
    totalFormulas = cart_formulas.reduce(
      (totalFormula, formula) =>
        totalFormula +
        formula.quantity *
          (formulaPrice(menu.formulas, formula.formulaId) +
            calculate_supplements_products(
              menu.formula_products,
              formula.formula_services
            )),
      0
    );
  }
  return { type: types.SET_TOTAL, total: (totalDishes + totalFormulas).toFixed(2) };
}
function calculate_supplements(additionalDishes, dishAdditionalDishes) {
  let totalSupplements = 0;
  if (dishAdditionalDishes && dishAdditionalDishes.length > 0) {
    const dAD = Array.isArray(dishAdditionalDishes)
      ? dishAdditionalDishes
      : [dishAdditionalDishes];
    totalSupplements = dAD.reduce(
      (totalSupp, suppdish) => totalSupp + supplementPrice(additionalDishes, suppdish),
      0
    );
  }
  return totalSupplements;
}
function calculate_supplements_products(formula_products, formula_services) {
  let totalProducts = 0;
  if (formula_services && formula_services.length > 0) {
    totalProducts = formula_services.reduce(
      (totalS, fs) => totalS + productPrice(formula_products, fs.productId),
      0
    );
  }

  return totalProducts;
}
function supplementPrice(additionalDishes, suppdish) {
  const supplement = additionalDishes.find((x) => x.id == suppdish);
  return parseFloat(supplement.price);
}
function dishPrice(dishes, dishID) {
  const dish = dishes.find((x) => x.id === dishID);
  return parseFloat(dish.price);
}
function formulaPrice(formulas, formulaId) {
  const formula = formulas.find((x) => x.id === formulaId);
  return formula.price;
}
function productPrice(products, productId) {
  const product = products.find((x) => x.id == productId);
  return product.suplement_price;
}
export function sendVerificationSms(code, phone) {
  return (dispatch) => {
    cartService.sendVerificationSms(code, phone).then(
      (res) => {
        console.log("sms sent");
      },
      (error) => {
        console.log(error);
      }
    );
  };
}
export function submitCashOrder(data) {
  return (dispatch) => {
    cartService.submitOrder(data).then(
      (res) => {
        dispatch(validatedCart(data.cart));
        dispatch(validatedTakeable(data.takableInfo));
        dispatch(resetTakableInfo());
        dispatch(resetCart());
        dispatch(validatedTotal(data.total));
        dispatch(successOrder(true));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function submitStripeOrder(data) {
  return (dispatch) => {
    dispatch(setIsProcessing(true));
    cartService.submitOrder(data).then(
      (res) => {
        if (res.data.status === "succeeded") {
          dispatch(validatedCart(data.cart));
          dispatch(validatedTakeable(data.takableInfo));
          dispatch(resetTakableInfo());
          dispatch(resetCart());
          dispatch(validatedTotal(data.total));
          dispatch(successOrder(true));

          dispatch(stripeRequireAction(false));
          dispatch(setPaymentIntentClientSecret(""));
        } else if (res.data.status === "requires_action") {
          dispatch(setIsProcessing(false));
          dispatch(stripeRequireAction(true));
          dispatch(setPaymentIntentClientSecret(res.data.payment_intent_client_secret));
        } else if (res.data.status === "error") {
          dispatch(setStripeError(res.data.message));
          dispatch(setIsProcessing(false));
        }
      },
      (error) => {
        dispatch(setIsProcessing(false));
        console.log("Error actions");
        console.log(error);
      }
    );
  };
}

export function setOrdersHistory(orders_history) {
  return { type: types.SET_ORDERS_HISTORY, orders_history };
}
export function loadingOrdersHistory(loading_orders_history) {
  return { type: types.LOADING_ORDERS_HISTORY, loading_orders_history };
}
export function fetchOrdersHistory(data) {
  return (dispatch) => {
    dispatch(loadingOrdersHistory(true));
    cartService.fetchOrdersHistory(data).then(
      (res) => {
        dispatch(loadingOrdersHistory(false));
        dispatch(setOrdersHistory(res.data.food_orders_history));
      },
      (error) => {
        dispatch(loadingOrdersHistory(false));
        console.log(error);
      }
    );
  };
}

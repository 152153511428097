import React, { useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

function PaymentForm({
  stripePaymentMethodHandler,
  isProcessing,
  payment_intent_client_secret,
  stripeRequireAction,
  total,
  stripe_error,
  public_key,
}) {
  const [stripePromise] = useState(loadStripe(public_key));

  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          stripePaymentMethodHandler={stripePaymentMethodHandler}
          isProcessing={isProcessing}
          payment_intent_client_secret={payment_intent_client_secret}
          stripeRequireAction={stripeRequireAction}
          total={total}
          stripe_error={stripe_error}
        />
      </Elements>
    </>
  );
}

export default PaymentForm;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as localeActions from "../../redux/actions/localeActions";
import * as cartActions from "../../redux/actions/cartActions";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Container, Offcanvas, Badge, Row, Col, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import CartDetails from "../static/shared/CartDetails";
import Moment from "react-moment";
import "moment-timezone";

function Header({ cart_actions, menu, cart, total, table_booker, website_section }) {
  const [section, setSection] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseCart = () => setShowCart(false);
  const handleShowCart = () => setShowCart(true);
  const removeFormula = (index) => {
    cart_actions.removeFormula(index);
  };
  const removeDish = (index) => {
    cart_actions.removeDish(index);
  };

  useEffect(() => {
    setSection(
      website_section ? (website_section === "menu" ? menu : table_booker) : false
    );
  }, [website_section, menu, table_booker]);

  const menu_section = website_section === "menu";
  const table_booker_section = website_section === "table_booker";

  return (
    <>
      {section && section.can_take_orders && (
        <header className="main-search-content">
          {menu_section && (
            <div className="cart-section">
              <span
                className="cart-icon"
                onClick={handleShowCart}
                aria-controls={`offcanvasNavbar-expand-left`}
              >
                <Badge bg="success" className="custom-badge">
                  {total} €
                </Badge>
                <FontAwesomeIcon icon={faShoppingBag} color="#fff" />
              </span>
            </div>
          )}
          <div className="details-title banner-container">
            <div className="banner-image">
              <img
                alt="Restaurant header"
                title="Restaurant header"
                src={section.entry.banner_image}
              />
              <div className="entry-basic-infos">
                <div className="entry-basic-infos-details">
                  <div className="small-entry-logo">
                    <img
                      alt="Restaurant Onesto profil"
                      title="Restaurant Onesto profil"
                      src={section.entry.logo_image}
                    />
                  </div>
                  <div className="info-items">
                    <h1 className="header-title left banner-title fn">
                      <Link to={`/${section?.entry.id}-${section?.entry.title}`}>
                        {section?.entry.company_name}
                      </Link>
                    </h1>
                    <div>
                      <h3>
                        {section?.entry.address.house_number}{" "}
                        {section?.entry.address.street_name}
                      </h3>
                      <h3>
                        L-{section?.entry.address.postal_code},{" "}
                        {section?.entry.address.city}
                      </h3>
                      <h3 className="pb-3">{section?.entry.address.country}</h3>
                    </div>
                  </div>
                  <div className="info-items">
                    <div className="contact-informations">
                      {section.entry.complete_number != "false" && (
                        <div className="contact-information">
                          <a
                            className="contact-information-item contact-track"
                            href={`tel:${section?.entry.complete_number}`}
                          >
                            <div className="contact-icon contact-tel"></div>
                          </a>
                        </div>
                      )}
                      {section?.entry.email && (
                        <div className="contact-information">
                          <a
                            className="contact-information-item contact-track"
                            href={`mailto:${section.entry.email}`}
                          >
                            <div className="contact-icon contact-email"></div>
                          </a>
                        </div>
                      )}
                      {section?.entry.url && (
                        <div className="contact-information">
                          <a
                            className="contact-information-item contact-track"
                            href={section?.entry.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <div className="contact-icon contact-website"></div>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nav className="navigation-container">
              <div className="navigation-settings-container">
                <div className="navigation-settings">
                  <div className="entry-logo">
                    <img
                      alt="Restaurant Onesto profil"
                      title="Restaurant Onesto profil"
                      src={section.entry.logo_image}
                    />
                  </div>
                  <div className="navigation-menu">
                    <ul>
                      <li className="active">
                        <div className="mt-3" onClick={() => setShowModal(true)}>
                          Détails
                        </div>
                      </li>
                      <li>
                        {menu_section && section.entry.can_book_table && (
                          <Link
                            className="entry-details mt-3"
                            to={`/table_booker/${section.entry.id}`}
                          >
                            Réserver une table
                          </Link>
                        )}
                      </li>
                      <li>
                        {table_booker_section && (
                          <Link
                            className="entry-details mt-3"
                            to={`/${section.entry.id}`}
                          >
                            Commander en ligne
                          </Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>

          <Offcanvas
            className="cart-offcanvas"
            show={showCart}
            onHide={handleCloseCart}
            placement="right"
          >
            <Container>
              <Offcanvas.Header>
                <span onClick={handleCloseCart}>
                  <FontAwesomeIcon icon={faArrowLeft} color="#303733" />
                </span>
                <h3 className="mt-1">Panier</h3>
                <span></span>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <CartDetails
                  total={total}
                  cart={cart}
                  menu={menu}
                  with_actions={true}
                  removeFormula={removeFormula}
                  removeDish={removeDish}
                />
                <hr />
                {menu && (
                  <Col className="mt-2 auto-margin" xs={12} lg={3} md={4} sm={6}>
                    <Link
                      to={`/${menu.entry.id}/orderValidation`}
                      className="btn-validate mb-3"
                      onClick={() => setShowCart(false)}
                    >
                      Valider la commande
                    </Link>
                  </Col>
                )}
              </Offcanvas.Body>
            </Container>
          </Offcanvas>

          <DetailsModal
            section={section}
            show={showModal}
            handleClose={() => setShowModal(false)}
            total={total}
          />
        </header>
      )}
    </>
  );
}

function DetailsModal({ show, handleClose, section, total }) {
  const days = {
    1: "lundi",
    2: "mardi",
    3: "mercredi",
    4: "jeudi",
    5: "vendredi",
    6: "samedi",
    7: "dimanche",
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <span onClick={handleClose}>
          <FontAwesomeIcon icon={faArrowLeft} color="#303733" />
        </span>
        <Modal.Title className="ml-2">Détails</Modal.Title>
        <span></span>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="mb-2 modal-title h4 green ">Heures d'ouvertures</div>
            </Col>
          </Row>
          {section.entry.formatted_opening_hours.map((f_op) => (
            <Row
              key={f_op.day}
              className={`mb-1 ${f_op.relevant_for_today ? "open- today" : ""}`}
            >
              <Col xs={4} className="semi-bold">
                {days[f_op.day]}
              </Col>
              {f_op.opening_hours.length === 0 ? (
                <Col>Fermé</Col>
              ) : (
                f_op.opening_hours.map((op, index) => (
                  <Col xs={4} key={index}>
                    <Moment
                      className="delivery-time date"
                      format="HH:mm"
                      tz="Europe/London"
                    >
                      {op.from}
                    </Moment>
                    -{" "}
                    <Moment
                      className="delivery-time date"
                      format="HH:mm"
                      tz="Europe/London"
                    >
                      {op.to}
                    </Moment>
                  </Col>
                ))
              )}
            </Row>
          ))}
          <hr />

          <Row>
            <Col xs={12}>
              <div className="mb-2 modal-title h4 green ">Modes de paiement</div>
            </Col>
          </Row>
          <Row>
            {section?.entry.payment_modes.map((pm) => (
              <Col xs={12} key={pm.id} className="">
                {pm.label}
              </Col>
            ))}
          </Row>
          {section.can_take_home && section.take_home.minimum_for_delivery > total && (
            <>
              <hr />
              <Row>
                <Col xs={12}>
                  <div className="mb-2 modal-title h4 green ">Informations</div>
                </Col>
              </Row>
              <Row>
                <div className="">
                  <div className="dnp-message">
                    Le montant minimum pour la livraison est de{" "}
                    {section.take_home.minimum_for_delivery}€.
                  </div>
                </div>
              </Row>
            </>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.localeReducer.locale,
    cart: state.cartReducer.cart,
    total: state.cartReducer.total,
    takableInfo: state.cartReducer.takableInfo,
    menu: state.menuReducer.menu,
    website_section: state.menuReducer.website_section,
    table_booker: state.tableBookerReducer.table_booker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(localeActions, dispatch),
    cart_actions: bindActionCreators(cartActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

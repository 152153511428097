import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function Formula({ formula, addFormulaToCart }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="formula mb-3">
        <div>
          {formula.formula_image ? (
            <img
              src={formula.formula_image}
              alt="formula preview"
              className="dish-image"
            />
          ) : (
            <img
              src="/images/placeholder/menu.png"
              alt="dish preview"
              className="dish-image"
            />
          )}
        </div>
        <div>
          <div className="formula-label mb-3">{formula.name}</div>
          <div className="price-action">
            <div className="formula-price">{formula.price} €</div>
            <div className="formula-action" onClick={handleShow}>
              <FontAwesomeIcon icon={faPlus} color="#303733" />
            </div>
          </div>
        </div>
      </div>
      <FormulaModal
        show={show}
        handleClose={handleClose}
        formula={formula}
        addFormulaToCart={addFormulaToCart}
      />
    </>
  );
}

const schema = yup
  .object({
    note: yup.string(),
    quantity: yup.number().positive().min(1),
  })
  .required();

function FormulaModal({ show, handleClose, formula, addFormulaToCart }) {
  const { register, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    addFormulaToCart({ ...data, formulaId: formula.id });
    handleClose();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} id={`hook-form-menu-${formula.id}`}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <span onClick={handleClose}>
            <FontAwesomeIcon icon={faArrowLeft} color="#303733" />
          </span>
          <Modal.Title className="ml-2">{formula.name}</Modal.Title>
          <span></span>
        </Modal.Header>
        <Modal.Body>
          <div className="form-section">
            <div className="form-group mt-3 mb-3">
              <label className="mb-2">Ajoutez une note pour ce produit </label>
              <input className="form-control" id="note" {...register("note")} />
            </div>

            {formula.formula_services.map((fs, index) => (
              <div className="form-group mt-3 mb-3" key={fs.id}>
                <label className="mb-2">{fs.name}</label>
                <input
                  type="hidden"
                  {...register(`formula_services[${index}].serviceId`)}
                  value={fs.id}
                />
                <select
                  {...register(`formula_services[${index}].productId`)}
                  className="form-select form-select-lg mb-3 products-select"
                >
                  {fs.formula_products.map((fp) => (
                    <option value={fp.id} key={fp.id}>
                      {fp.name}
                      {fp.suplement_price > 0 ? ` +${fp.suplement_price} €` : ""}
                    </option>
                  ))}
                </select>
              </div>
            ))}

            <div className="form-group quantity-group mt-3 mb-3">
              <div
                className="quantity-action"
                onClick={() =>
                  parseInt(getValues("quantity")) > 1 &&
                  setValue("quantity", parseInt(getValues("quantity")) - 1)
                }
              >
                <FontAwesomeIcon icon={faMinus} color="#303733" />
              </div>
              <input
                type="number"
                className="quantity-input"
                id="note"
                min="1"
                max="5"
                defaultValue="1"
                {...register("quantity")}
              />
              <div
                className="quantity-action"
                onClick={() =>
                  parseInt(getValues("quantity")) < 5 &&
                  setValue("quantity", parseInt(getValues("quantity")) + 1)
                }
              >
                <FontAwesomeIcon icon={faPlus} color="#303733" />
              </div>
              <div className="dish-price mt-1">
                <span className="mr-2">Prix du plat </span>
                <span>{formula.price}€</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <input
            type="submit"
            form={`hook-form-menu-${formula.id}`}
            className="btn btn-success"
            value="Ajouter au panier"
          />
        </Modal.Footer>
      </Modal>
    </form>
  );
}

export default Formula;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-phone-input-2/lib/style.css";

function OrderConfirmation({
  submitConfirmation,
  sendVerificationSms,
  takeType,
  commanderAddress,
  userInfo,
  setUserInfo,
}) {
  const [showModal, setShowModal] = useState(false);
  const [validationCode, setValidationCode] = useState();
  const [userCode, setUserCode] = useState("");
  const [successCode, setSuccessCode] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const schema = yup
    .object({
      name: yup.string().required(),
      phone: yup.string().required(),
      email: yup.string().email().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    const code = getRandomCode(1000, 9999);
    setValidationCode(code);
    sendVerificationSms(code, data.phone);

    setUserInfo(data);
    handleShow();
  };
  const regenerateCode = () => {
    const code = getRandomCode(1000, 9999);
    setValidationCode(code);
    sendVerificationSms(code);
  };
  const submitCOrder = () => {
    submitConfirmation(userInfo);
  };
  const onChangeUserCode = (e) => {
    setUserCode(e.target.value);
  };
  useEffect(() => {
    if (userCode.length === 4) {
      if (parseInt(userCode) === validationCode) {
        setSuccessCode(true);
      } else {
        setSuccessCode(false);
      }
    }
  }, [userCode, validationCode]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)} className="admin-form">
        <Row className="pt-3 mb-2">
          <Col md={12}>
            <div className="form-group mb-3">
              <label className="mb-2">Votre nom</label>
              <input className="form-control" id="name" {...register("name")} />
              <small className="form-text text-muted">{errors.name?.message}</small>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2">Votre téléphone</label>
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    enableSearch
                    country={"lu"}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <small className="form-text text-muted">{errors.phone?.message}</small>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2">Votre e-mail</label>
              <input className="form-control" id="email" {...register("email")} />
              <small className="form-text text-muted">{errors.email?.message}</small>
            </div>
          </Col>
        </Row>
        <Row>
          {takeType === "take_home" && (
            <Col className="mb-3">
              <span className="delivery-address">Adresse de livraison </span>

              <span className="delivery-address-info">
                {[
                  commanderAddress.address_number,
                  commanderAddress.address_line,
                  commanderAddress.locality,
                  commanderAddress.zip_code,
                  commanderAddress.country,
                ].join(", ")}
              </span>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} lg={3} md={4} sm={6} className="flex-center">
            <button type="submit" className="mt-2 mb-4-5 btn-validate">
              Valider
            </button>
          </Col>
        </Row>
      </form>

      <ValidationModal
        submitConfirmation={submitCOrder}
        showModal={showModal}
        handleClose={handleClose}
        onChangeUserCode={onChangeUserCode}
        userCode={userCode}
        successCode={successCode}
        regenerateCode={regenerateCode}
      />
    </Container>
  );
}

function ValidationModal({
  submitConfirmation,
  showModal,
  handleClose,
  onChangeUserCode,
  userCode,
  successCode,
  regenerateCode,
}) {
  const [showRefresh, setShowRefresh] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setShowRefresh(true);
      }, 10000);
    }
  }, [showModal]);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Vérification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group mb-3">
          <label className="mb-3">
            Un code de vérification vous a été envoyé par SMS, indiquez le code :
          </label>
          <input
            className="form-control"
            name="user_code"
            value={userCode}
            onChange={onChangeUserCode}
            maxLength="4"
          />
          {userCode.length === 4 && !successCode && (
            <small className="form-text text-muted">Code de validation incorrecte</small>
          )}
        </div>
        {showRefresh && !successCode && (
          <div>
            <span>Vous n'avez pas reçu le code ? </span>
            <span className="re-send" onClick={regenerateCode}>
              Renvoyer.
            </span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
        <Button
          variant="success"
          onClick={submitConfirmation}
          disabled={userCode.length !== 4 || !successCode}
        >
          Confirmer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function getRandomCode(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export default OrderConfirmation;

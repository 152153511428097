const initCart = window.localStorage.getItem("menuCart")
  ? JSON.parse(window.localStorage.getItem("menuCart"))
  : { dishes: [], formulas: [] };
export default function cartReducer(
  state = {
    cart: initCart,
    total: 0,
    takableInfo: false,
    orders_history: false,
    loading_orders_history: false,
    success_order: false,
    validated_cart: false,
    validated_takeable: false,
    validated_total: 0,
    verifiedDistance: false,
    isProcessing: false,
    stripeRequireAction: false,
    payment_intent_client_secret: "",
    stripe_error: "",
  },
  action
) {
  switch (action.type) {
    case "ADD_DISH_TO_CART":
      return {
        ...state,
        cart: { ...state.cart, dishes: [...state.cart.dishes, action.dish] },
      };
    case "ADD_FORMULA_TO_CART":
      return {
        ...state,
        cart: { ...state.cart, formulas: [...state.cart.formulas, action.formula] },
      };
    case "REMOVE_FORMULA_FROM_CART":
      let newFormulas = [...state.cart.formulas];
      newFormulas.splice(action.formula_index, 1);
      return {
        ...state,
        cart: { ...state.cart, formulas: newFormulas },
      };
    case "REMOVE_DISH_FROM_CART":
      let newDishes = [...state.cart.dishes];
      newDishes.splice(action.dish_index, 1);
      return {
        ...state,
        cart: { ...state.cart, dishes: newDishes },
      };
    case "LOAD_CART":
      return {
        ...state,
        cart: action.cart,
      };
    case "SET_ORDERS_HISTORY":
      return {
        ...state,
        orders_history: action.orders_history,
      };
    case "LOADING_ORDERS_HISTORY":
      return {
        ...state,
        loading_orders_history: action.loading_orders_history,
      };

    case "SUCCESS_ORDER":
      return {
        ...state,
        success_order: action.success_order,
      };
    case "VALIDATED_CART":
      return {
        ...state,
        validated_cart: action.validated_cart,
      };
    case "VALIDATED_TAKEABLE":
      return {
        ...state,
        validated_takeable: action.validated_takeable,
      };

    case "SET_TOTAL":
      return {
        ...state,
        total: action.total,
      };
    case "SET_VALIDATED_TOTAL":
      return {
        ...state,
        validated_total: action.validated_total,
      };
    case "RESET_CART":
      return {
        ...state,
        cart: { dishes: [], formulas: [] },
      };
    case "RESET_TAKABLE_INFO":
      return {
        ...state,
        takableInfo: false,
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((item) => action.productId !== item),
      };
    case "SET_TAKABLE_INFO":
      return {
        ...state,
        takableInfo: action.takableInfo,
      };
    case "SET_IS_PROCESSING":
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case "STRIPE_REQUIRE_ACTION":
      return {
        ...state,
        stripeRequireAction: action.stripeRequireAction,
      };
    case "SET_PAYMENT_INTENT_CLIENT_SECRET":
      return {
        ...state,
        payment_intent_client_secret: action.payment_intent_client_secret,
      };
    case "SET_STRIPE_ERROR":
      return {
        ...state,
        stripe_error: action.stripe_error,
      };
    default:
      return state;
  }
}

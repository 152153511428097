import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { addMinutes } from "date-fns";
import CartDetails from "./shared/CartDetails";
import Moment from "react-moment";

function OrderRecap({ validated_cart, menu, validated_total, validated_takeable }) {
  return (
    <Container>
      <Row className="pt-2 mb-3">
        <Col md={12}>
          <div className="order-recap-title">Nous avons enregistré votre demande.</div>
          <div className="order-recap-sous-title">
            Nous vous enverrons un e-mail pour confirmer la commande.
          </div>
        </Col>
        <Col md={12}>
          <div className="order-recap-sub-title my-3">
            Voici le récapitulatif de votre commande :
          </div>
          <CartDetails
            cart={validated_cart}
            total={validated_total}
            menu={menu}
            with_actions={false}
          />
          <hr />
        </Col>
        <Col md={12}>
          <div className="order-recap-sub-title my-3">Mode de livraison choisi</div>
          {validated_takeable.takeType === "take_home" && (
            <div className="mb-3 delivery-radios order-recap">
              <span className="delivery-picto">
                <img src="/images/pictos/take_home.png" alt="delivery picto" />
              </span>
              <label>
                <span>Livraison à domicile</span>
              </label>
            </div>
          )}
          {validated_takeable.takeType === "take_away" && (
            <div className="mb-4-5 delivery-radios order-recap">
              <span className="delivery-picto">
                <img src="/images/pictos/take_away.png" alt="delivery picto" />
              </span>
              <label>
                <span>Retrait sur place</span>
              </label>
            </div>
          )}

          <hr />
        </Col>
        <Col md={12}>
          <div className="order-recap-sub-title my-3">Mode de paiement accepté</div>
          <Row>
            {menu?.entry.payment_modes.map((pm) => (
              <Col xs={12} key={pm.id} className="">
                <div className="delivery-time">{pm.label}</div>
              </Col>
            ))}
          </Row>

          <hr />
        </Col>
        <Col md={12} className="my-3">
          <span className="delivery-time">Livraison de la commande prévue le</span>
          <Moment className="delivery-time date" format="DD/MM/YYYY">
            {validated_takeable.startHour}
          </Moment>
          <span className="delivery-time">entre</span>
          <Moment className="delivery-time date" format="HH:mm">
            {validated_takeable.startHour}
          </Moment>
          <span className="delivery-time">et</span>
          <Moment className="delivery-time date" format="HH:mm">
            {addMinutes(
              validated_takeable.startHour,
              menu[validated_takeable.takeType].take_delay
            )}
          </Moment>
        </Col>
        <Col xs={12} className="mb-1">
          <hr />
        </Col>
      </Row>
    </Container>
  );
}

export default OrderRecap;

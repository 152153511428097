import configGlobal from "../../config";
const axios = require("axios");

export const tableBookerService = {
  fetchTableBooker,
  fetchReservationsHistory,
  bookTable,
  fetchTableReservation,
};

function fetchTableBooker(id) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios.get(`${configGlobal.api_url}/table_booker/${id}`, config);
}

function fetchReservationsHistory(data) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return axios.post(
    `${configGlobal.api_url}/reservations_history`,
    { reservations_data: data },
    config
  );
}

function bookTable(data) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return axios.post(
    `${configGlobal.api_url}/table_reservation`,
    { table_reservation: data },
    config
  );
}

function fetchTableReservation(reservation_id) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return axios.get(`${configGlobal.api_url}/table_reservation/${reservation_id}`, config);
}

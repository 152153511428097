import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { AnimatePresence, motion } from "framer-motion";

function CartDetails({ total, cart, menu, removeFormula, removeDish, with_actions }) {
  const [cart_dishes, setCartDishes] = useState([]);
  const [cart_formulas, setCartFormulas] = useState([]);

  useEffect(() => {
    if (menu) {
      const entry_id = menu.entry.id;
      setCartDishes([...cart.dishes.filter((x) => x.restaurantId === entry_id)]);
      setCartFormulas([...cart.formulas.filter((x) => x.restaurantId === entry_id)]);
    }
  }, [cart, menu]);

  return (
    <>
      <AnimatePresence>
        <Row>
          {cart_dishes.map((dish, index) => (
            <Col md={6} lg={6} xs={12} key={index}>
              <DishContainer
                dishInfo={dish}
                menu={menu}
                removeDish={() => removeDish(index)}
                with_actions={with_actions}
              />
            </Col>
          ))}
        </Row>
      </AnimatePresence>
      <AnimatePresence>
        <Row>
          {cart_formulas.map((formula, index) => (
            <Col md={6} lg={6} xs={12} key={index}>
              <FormulaContainer
                formulaInfo={formula}
                menu={menu}
                removeFormula={() => removeFormula(index)}
                with_actions={with_actions}
              />
            </Col>
          ))}
        </Row>
      </AnimatePresence>

      <hr />
      <div className="order-total py-3">
        <span>Total de la commande</span>
        <span>{total} €</span>
      </div>
    </>
  );
}

function FormulaContainer({ formulaInfo, menu, removeFormula, with_actions }) {
  const formula = findFormula(menu.formulas, formulaInfo.formulaId);
  const has_formula_products =
    formulaInfo.formula_services && formulaInfo.formula_services.length > 0;
  const variantsContainer = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    transition: {
      duration: 1,
    },
  };

  return (
    <motion.div
      variants={variantsContainer}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="mb-3 formula"
    >
      <div>
        {formula.formula_image ? (
          <img src={formula.formula_image} alt="formula preview" className="dish-image" />
        ) : (
          <img
            src="/images/placeholder/menu.png"
            alt="dish preview"
            className="dish-image"
          />
        )}
      </div>
      <div>
        <div>
          <div className="price-action">
            <div className="dish-label">{formula.name}</div>
            {with_actions && (
              <div onClick={removeFormula} className="remove-action">
                <FontAwesomeIcon icon={faTrash} color="#00d793" />
              </div>
            )}
          </div>
        </div>
        <div className="price-action mt-2">
          <div className="dish-price">{formula.price} €</div>
        </div>
      </div>

      {has_formula_products && <div className="small-supp">Détails</div>}
      {has_formula_products && (
        <div className="supp-container">
          {formulaInfo.formula_services.map((fs, index) => (
            <FormulaService
              key={index}
              formulaService={fs}
              formulaServices={menu.formula_services}
            />
          ))}
        </div>
      )}

      <div className="small-supp">Quantité</div>
      <div className="supp-container">
        <div className="supp-label">{formulaInfo.quantity}</div>
      </div>

      <div className="small-supp mt-1">Total</div>
      <div className="supp-container">
        <span className="total-price">
          {calculateFormula(formulaInfo, menu.formulas, menu.formula_products)} €
        </span>
      </div>
    </motion.div>
  );
}

function calculateFormula(formulaInfo, formulas, formula_products) {
  const formula = formulas.find((x) => x.id === formulaInfo.formulaId);

  const supplements_price = calculate_supplements_products(
    formula,
    formulaInfo.formula_services,
    formula_products
  );

  const total = formulaInfo.quantity * (formula.price + supplements_price);

  return total;
}
function calculate_supplements_products(
  formula,
  cart_formula_services,
  formula_products
) {
  let totalProducts = 0;
  if (cart_formula_services && cart_formula_services.length > 0) {
    totalProducts = cart_formula_services.reduce(
      (totalS, fs) => totalS + productPrice(formula_products, fs.productId),
      0
    );
  }
  return totalProducts;
}
function productPrice(products, productId) {
  const product = products.find((x) => x.id == productId);
  return product.suplement_price;
}
function FormulaService({ formulaService, formulaServices }) {
  const fs = formulaServices.find((x) => x.id == formulaService.serviceId);
  const fp = fs.formula_products.find((x) => x.id == formulaService.productId);
  return (
    <div className="supp-label">
      <div>
        <span className="bold">{fs.name}</span> • {fp.name}{" "}
        {fp.suplement_price > 0 ? ` +${fp.suplement_price} €` : ""}
      </div>
    </div>
  );
}
function DishContainer({ dishInfo, menu, removeDish, with_actions }) {
  const dish = findDish(menu.dishes, dishInfo.dishId);
  const has_supplements =
    dishInfo.additional_dishes !== false &&
    dishInfo.additional_dishes &&
    dishInfo.additional_dishes.length > 0;

  const additional_dishes =
    has_supplements && dishInfo.additional_dishes.length === 1
      ? [dishInfo.additional_dishes]
      : dishInfo.additional_dishes;

  const variantsContainer = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    transition: {
      duration: 1,
    },
  };

  return (
    <motion.div
      variants={variantsContainer}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="mb-3 dish"
    >
      <div>
        {dish.dish_image ? (
          <img src={dish.dish_image} alt="dish preview" className="dish-image" />
        ) : (
          <img
            src="/images/placeholder/menu.png"
            alt="dish preview"
            className="dish-image"
          />
        )}
      </div>
      <div>
        <div>
          <div className="price-action">
            <div className="dish-label">{dish.label}</div>
            {with_actions && (
              <div onClick={removeDish} className="remove-action">
                <FontAwesomeIcon icon={faTrash} color="#00d793" />
              </div>
            )}
          </div>
          <div className="dish-description">{dish.dish_category}</div>
        </div>
        <div className="price-action">
          <div className="dish-price">{dish.price} €</div>
        </div>
      </div>

      {has_supplements && <div className="small-supp">Supplément</div>}
      {has_supplements && (
        <div className="supp-container">
          {additional_dishes.map((ad, index) => (
            <AdditionalDish
              key={index}
              additional_dishes={menu.additional_dishes}
              dishAd={ad}
            />
          ))}
        </div>
      )}
      <div className="small-supp">Quantité</div>
      <div className="supp-container">
        <div className="supp-label">{dishInfo.quantity}</div>
      </div>

      <div className="small-supp mt-1">Total</div>
      <div className="supp-container">
        <span className="total-price">
          {calculateDish(
            menu.dishes,
            menu.additional_dishes,
            dish,
            dishInfo.additional_dishes,
            dishInfo.quantity
          )}{" "}
          €
        </span>
      </div>
    </motion.div>
  );
}
function findDish(dishes, dishID) {
  const dish = dishes.find((x) => x.id === dishID);
  return dish;
}
function findFormula(formulas, formulaId) {
  const formula = formulas.find((x) => x.id === formulaId);
  return formula;
}
function AdditionalDish({ additional_dishes, dishAd }) {
  const additionalDish = additional_dishes.find((x) => x.id == dishAd);
  return (
    <div className="price-action">
      <div className="supp-label">{additionalDish.label}</div>
      <div className="supp-label">{` +${additionalDish.price} €`}</div>
    </div>
  );
}
function calculateDish(
  dishes,
  additional_dishes,
  dish,
  dish_additional_dishes,
  quantity
) {
  const dishPrices = dishPrice(dishes, dish.id);
  const dishSupplements = calculate_supplements(
    additional_dishes,
    dish_additional_dishes
  );

  const total = quantity * (parseFloat(dishPrices) + parseFloat(dishSupplements));
  return total;
}
function dishPrice(dishes, dishID) {
  const dish = dishes.find((x) => x.id === dishID);
  return dish.price;
}
function calculate_supplements(additionalDishes, dishAdditionalDishes) {
  let totalSupplements = 0;
  if (dishAdditionalDishes && dishAdditionalDishes.length > 0) {
    const dAD = Array.isArray(dishAdditionalDishes)
      ? dishAdditionalDishes
      : [dishAdditionalDishes];
    totalSupplements = dAD.reduce(
      (totalSupp, suppdish) => totalSupp + supplementPrice(additionalDishes, suppdish),
      0
    );
  }
  return totalSupplements;
}
function supplementPrice(additionalDishes, suppdish) {
  const supplement = additionalDishes.find((x) => x.id == suppdish);
  return supplement.price;
}

export default CartDetails;

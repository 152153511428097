import { combineReducers } from "redux";
import localeReducer from "./localeReducer";
import menuReducer from "./menuReducer";
import cartReducer from "./cartReducer";
import tableBookerReducer from "./tableBookerReducer";

const rootReducer = combineReducers({
  tableBookerReducer: tableBookerReducer,
  cartReducer: cartReducer,
  menuReducer: menuReducer,
  localeReducer: localeReducer,
});

export default rootReducer;

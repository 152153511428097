let config = {};

const host =
  process.env.NODE_ENV === "production"
    ? "https://yellowlu.herokuapp.com"
    : "http://localhost:3000";
config = {
  base_url: host,
  api_url: host + "/api/v2",
  client_id: "f8e0be082c16d5761295de2eea65664ee94eb70e08d8186494b26eaeef12c8ad",
  token: "bcb73e0537eca0ac51b1b11bac29c5b41673dfda00195bc3c0e8a03d28e9d644",
};

export default config;

export default function localeReducer(
  state = { menu: false, loading_menu: false, error_menu: false, website_section: false },
  action
) {
  switch (action.type) {
    case "SET_MENU":
      return {
        ...state,
        menu: action.menu,
      };
    case "SET_WEBSITE_SECTION":
      return {
        ...state,
        website_section: action.website_section,
      };
    case "LOADING_MENU":
      return {
        ...state,
        loading_menu: action.loading_menu,
      };
    case "ERROR_MENU":
      return {
        ...state,
        error_menu: action.error_menu,
      };
    default:
      return state;
  }
}

import React from "react";

function Steps({ step, setStep }) {
  return (
    <div className={`process-wrap active-step${step}`}>
      <div className="process-main">
        <div className="col-4">
          <div className="process-step-cont" onClick={() => setStep(1)}>
            <div className={`process-step step-1 ${step === 1 ? "current" : ""}`}></div>
            <span className="process-label">Livraison</span>
          </div>
        </div>
        <div className="col-4">
          <div className="process-step-cont" onClick={() => setStep(2)}>
            <div className={`process-step step-2 ${step === 2 ? "current" : ""}`}></div>
            <span className="process-label">Contact</span>
          </div>
        </div>
        <div className="col-4">
          <div className="process-step-cont">
            <div className={`process-step step-3 ${step === 3 ? "current" : ""}`}></div>
            <span className="process-label">Paiement</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps;

import { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import { Bars } from "react-loader-spinner";

export default function CheckoutForm({
  stripePaymentMethodHandler,
  isProcessing,
  payment_intent_client_secret,
  stripeRequireAction,
  total,
  stripe_error,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
      },
    });
    stripePaymentMethodHandler({
      payment_method: "Card",
      payment_method_id: result.paymentMethod.id,
      total: total,
    });
  };
  useEffect(() => {
    if (stripeRequireAction && payment_intent_client_secret && !isProcessing) {
      const handleCard = async () => {
        const data = await stripe.handleCardAction(payment_intent_client_secret);
        return data;
      };

      handleCard()
        .then((result) => {
          stripePaymentMethodHandler({
            payment_method: "Card",
            payment_intent_id: result.paymentIntent.id,
            total: total,
          });
        })
        .catch((error) => {
          console.log("Error Componant");
          console.log(error);
        });
      // make sure to catch any error
    }
  }, [stripeRequireAction, payment_intent_client_secret, total]);

  return (
    <div className={`payment-section mt-2`}>
      <div className={`${isProcessing ? "payment-processing" : ""}`}>
        <Bars
          height="80"
          width="80"
          color="#ffde0b"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass="procesing-bar"
          visible={isProcessing}
        />
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardSection name={name} setName={(nm) => setName(nm)} />
          <button
            disabled={isProcessing || !stripe || !elements}
            id="submit"
            className="mb-3 mt-4 btn-validate"
          >
            <span id="button-text">{isProcessing ? "Processing ... " : "Commander"}</span>
          </button>
          {stripe_error && <div id="payment-message">{stripe_error}</div>}
        </form>
      </div>
    </div>
  );
}
